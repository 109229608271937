<template>
  <div>修改通知邮件</div>
</template>

<script>
export default {
  name: "updatePassword"
}
</script>

<style scoped>

</style>